import React, { useState, useEffect, useRef } from "react";
import { Col, Image, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import star from "../../assets/star.svg";
import certificate from "../../assets/certificate.svg";
import certifiedCheck from "../../assets/certifiedCheck.svg";
import greyCircle from "../../assets/greyCircle.svg";
import "./SellerProfile.css";
import CompanyAvatar from "../CompanyAvatar";
import Reviews from "./Reviews";
import Services from "./Services";
import PreviewPopup from "../FilePreviewPopup/PreviewPopup";
import Overlay from "../Overlay/Overlay";
const UserProfileComponent = ({ supplier, onClose, backUrl }) => {
  const [selectedTab, setSelectedTab] = useState("about");
  const userProfileRef = useRef(null);
  const [isFullProfileOpen, setIsFullProfileOpen] = useState(false);
  const [showCertificates, setShowCertificates] = useState(true);
  const [isPreview, setPreview] = useState(false);

  const navigate = useNavigate();

  const handleOpenFullProfile = () => {
    setIsFullProfileOpen(!isFullProfileOpen);
    navigate(`/buyer-dashboard/marketplace/user-profile`, {
      state: { supplier, backUrl },
    });
  };

  const handleOpenCertificate = () => {
    setPreview(true);
  };

  const closePreview = () => {
    setPreview(false);
  };

  const handleMessage = () => {
    navigate("/buyer-dashboard/collaborations", {
      state: { supplier, backUrl },
    });
  };
  const handleTabClick = (tab) => {
    if (tab !== "about") setShowCertificates(false);
    else {
      setShowCertificates(true);
    }
    setSelectedTab(tab);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        userProfileRef.current &&
        !userProfileRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const divFlexRow = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
  const divFlexColumn = {
    display: "flex",
    flexDirection: "column",
  };
  const tabStyle = {
    padding: "10px",
    cursor: "pointer",
  };

  const activeTabStyle = {
    color: "#0E8080",
    borderBottom: "4px solid #0E8080",
    borderBottomLeftRadius: "1px", // Set a small border radius for the left corner
    borderBottomRightRadius: "1px",
  };

  const inactiveTabStyle = {
    color: "#7B7B7B",
  };

  const generateInfoBox = (label, value) => {
    return (
      <div className="info-box">
        <p
          style={{
            color: "grey",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {label} <span style={{ color: "black" }}>{value}</span>
        </p>
      </div>
    );
  };

  return (
    <Col>
      <Card.Body ref={userProfileRef} className="user-profile-card-body">
        <div
          style={{
            ...divFlexRow,
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div style={divFlexRow}>
            {supplier.company.companyProfilePicture ? (
              <Image
                src={supplier.company.companyProfilePicture}
                alt=""
                width="38px"
                height="38px"
                style={{
                  borderRadius: "9px",
                  marginRight: "10px",
                }}
              />
            ) : (
              <div style={{ marginRight: "10px" }}>
                <CompanyAvatar
                  companyName={supplier.company.name}
                  size="38px"
                />
              </div>
            )}

            <Card.Subtitle className="user-profile-subtitle">
              {supplier.company.name}
            </Card.Subtitle>
          </div>

          <div style={{ ...divFlexRow, alignItems: "center", gap: "10px" }}>
            <button type="submit" className="hire-btn" onClick={handleMessage}>
              Message
            </button>
            <button
              onClick={() => handleOpenFullProfile()}
              className="open-profile-button"
            >
              Full Profile
            </button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "56px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ color: "#7B7B7B", marginRight: "10px" }}>
              {supplier.address.city},&nbsp;
              {supplier.address.state},&nbsp;
              {supplier.address.country}
            </p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ color: "#7B7B7B" }}>Rating:</p>
            <span className="supplier-rating">
              {/* <img src={star} alt="star" /> */}
              <p>{supplier.supplierProfile.rating}</p>
            </span>
            {supplier.supplierProfile.certificates.length > 0 && (
              <span style={{ display: "flex" }}>
                <img
                  src={certifiedCheck}
                  alt=""
                  style={{ marginLeft: "16px", marginRight: "5px" }}
                />
                <p
                  style={{
                    fontSize: "14px",
                    background:
                      "linear-gradient(180deg, #CC9866 9.29%, #792E8E 98.58%)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Certified
                </p>
              </span>
            )}
          </div>
        </div>
        <Card.Subtitle className="main-contact-subtitle">
          Main Contact
        </Card.Subtitle>
        <div style={{ ...divFlexRow, marginTop: "16px" }}>
          {supplier.supplierProfile.profilePicture ? (
            <Image
              src={supplier.supplierProfile.profilePicture}
              alt=""
              width="46px"
              height="46px"
              style={{ borderRadius: "9px", marginRight: "10px" }}
            />
          ) : (
            <div style={{ marginRight: "10px" }}>
              <Image
                src={greyCircle}
                alt=""
                width="46px"
                height="46px"
                style={{ borderRadius: "9px" }}
              />
            </div>
          )}
          <div style={{ ...divFlexColumn, alignItems: "flex-start" }}>
            <span>{supplier.supplierProfile.name}</span>
            <span style={{ color: "#7B7B7B" }}>
              {supplier.supplierProfile.primaryRole}
            </span>
          </div>
        </div>

        <div className="company-info-container">
          <Card.Subtitle
            className="integration-list-subtitle"
            style={{ marginBottom: "16px" }}
          >
            Company info
          </Card.Subtitle>
          <div className="info-boxes">
            {generateInfoBox("Industry", supplier.company.industry)}
            {generateInfoBox(
              "Diversity Group",
              supplier.supplierProfile.diversity.diversityStatus
            )}
            {generateInfoBox("Employees", supplier.company.size)}
          </div>
        </div>

        <div className="tabs" style={divFlexRow}>
          <div
            className="tab"
            style={{
              ...tabStyle,
              ...(selectedTab === "about" ? activeTabStyle : inactiveTabStyle),
            }}
            onClick={() => handleTabClick("about")}
          >
            About
          </div>
          <div
            className="tab"
            style={{
              ...tabStyle,
              ...(selectedTab === "skills" ? activeTabStyle : inactiveTabStyle),
            }}
            onClick={() => handleTabClick("skills")}
          >
            Skills
          </div>
          <div
            className="tab"
            style={{
              ...tabStyle,
              ...(selectedTab === "services"
                ? activeTabStyle
                : inactiveTabStyle),
            }}
            onClick={() => handleTabClick("services")}
          >
            Services
          </div>
          <div
            className="tab"
            style={{
              ...tabStyle,
              ...(selectedTab === "reviews"
                ? activeTabStyle
                : inactiveTabStyle),
            }}
            onClick={() => handleTabClick("reviews")}
          >
            Reviews
          </div>
        </div>

        <div className="tab-content">
          {selectedTab === "about" && <p>{supplier.supplierProfile.bio}</p>}
          {selectedTab === "skills" && (
            <div className="list-of-skills-pers-profile">
              {Object.keys(supplier.supplierProfile.skills).map(
                (skillType, index) => (
                  <div key={index}>
                    <h3>
                      {skillType === "technical"
                        ? "Technical Skills"
                        : skillType === "industry"
                        ? "Industry Skills"
                        : skillType === "company"
                        ? "Company Skills"
                        : skillType}
                    </h3>
                    <div className="skills-row">
                      {supplier.supplierProfile.skills[skillType].map(
                        (skill, skillIndex) => (
                          <span key={skillIndex}>{skill}</span>
                        )
                      )}
                    </div>
                  </div>
                )
              )}

              <div>
                <h3>Certificates</h3>
                <div className="skills-row">
                  {supplier.supplierProfile.certificates.map(
                    (skill, skillIndex) => (
                      <span key={skillIndex}>{skill}</span>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
          {selectedTab === "services" && (
            <Services services={supplier.supplierProfile.services} />
          )}
          {selectedTab === "reviews" && <Reviews />}
        </div>
        {showCertificates && (
          <>
            <Card.Subtitle
              className="integration-list-subtitle"
              style={{ marginBottom: "8px" }}
            >
              Certificates
            </Card.Subtitle>
            {supplier.supplierProfile.certificates.length > 0 && (
              <div
                style={{
                  ...divFlexColumn,
                  border: "1px solid #ddd",
                  padding: "15px 14px",
                  gap: "34px",
                  borderRadius: "8px",
                  marginBottom: "44px",
                }}
              >
                {supplier.supplierProfile.certificates.map((cert, i) => (
                  <div key={`${i} + ${cert}`} style={divFlexRow}>
                    <Image
                      src={certificate}
                      alt=""
                      width="18px"
                      height="18px"
                      style={{ borderRadius: "9px", marginRight: "10px" }}
                    />
                    <span onClick={() => handleOpenCertificate()}>{cert}</span>
                    {isPreview && (
                      <>
                        <Overlay />
                        <PreviewPopup
                          file={certificate}
                          onClose={closePreview}
                        />
                      </>
                    )}
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        {/* <div>
          <Card.Subtitle
            className="tab"
            style={{
              ...divFlexRow,
              borderBottom: "4px solid #9a9a9a",
              width: "70px",
            }}
          >
            Actions
          </Card.Subtitle>
          <p style={{ ...divFlexRow, color: "#7B7B7B" }}>No new actions</p>
        </div> */}
      </Card.Body>
    </Col>
  );
};

export default UserProfileComponent;
